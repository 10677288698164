import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loaderStatus: any = new BehaviorSubject(status);
  currentLoaderService: any = this.loaderStatus.asObservable();
  constructor() { }
  changeLoaderStatus(status:any): any {
    this.loaderStatus.next(status)
  }
}
