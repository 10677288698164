export class Alert {
  type: AlertType
  message: string
  timeoutValue: number
  status: boolean
  signupStatus: any
}

export enum AlertType {
  Success,
  Error,
  Info,
  Warning,
}
