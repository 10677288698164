import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http'
import { AlertComponent } from './_directives';
import { AlertService } from './_services';
import { LoaderModule } from './loader/loader.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatProgressSpinnerModule } from '@angular/material';
import { EqualValidator } from './_directives/equal-validator.directive'

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AlertComponent,
    EqualValidator
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    LoaderModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule
  ],
  providers: [AlertService],
  bootstrap: [AppComponent]
})
export class AppModule { }
