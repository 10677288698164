import { NgModule } from '@angular/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader.component';
import{LoaderService} from './loader.service';
@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule
  ],

  declarations: [LoaderComponent],
  exports:[LoaderComponent],
  providers:[LoaderService]
})
export class LoaderModule { }
