import { Component, OnInit } from '@angular/core';
import { HomeService } from './home.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../_services';
import { LoaderService } from '../loader/loader.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  btnDisable: any = false
  model:any={}
  id:any
  tid:any
  url:any
  sub: any


  constructor(private homeService: HomeService,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private loaderservice: LoaderService,) { 
    this.sub = this.route.params.subscribe((params: any) => {
      this.tid = params.id
    })
  }

  ngOnInit() {
    if(this.tid){
      this.updatedata()
    }
  }

  submit(valid: any): void {
    if (valid) {
      const sendData:any={
        first_name : this.model.fname,
        last_name : this.model.lname,
        email : this.model.email,
        phone : this.model.phone,
        amount_acres : this.model.acresum,
        number_of_tests : this.model.haneyorder,
        transaction_id : ''

      }

      console.log(this.model);
      this.homeService.addData(sendData).subscribe(
        (data:any)=>{
          console.log(data);
          this.id = data.id
        }
      )

      const senddata :any = {
        recordId : this.id
      }
      
      this.homeService.checkout(senddata).subscribe(
        (data:any) => {
          console.log(data);
          this.url = data.result.url

          console.log(this.url);
          

          window.location.href = this.url;
        }
      )
    }
  }

  async submitAsync(valid: any): Promise<void> {
    if (valid) {
      const sendData: any = {
        first_name: this.model.fname,
        last_name: this.model.lname,
        email: this.model.email,
        phone: this.model.phone,
        amount_acres: this.model.acresum,
        number_of_tests: this.model.haneyorder,
        transaction_id: ''
      };
  
      console.log(this.model);
      this.btnDisable = true
  
      try {
        const data: any = await this.homeService.addData(sendData).toPromise();
        
  
        console.log(data);
        this.id = data.id;
  
        const senddata: any = {
          recordId: this.id
        };
  
        try {
          const checkoutData: any = await this.homeService.checkout(senddata).toPromise();
  
          console.log(checkoutData);
          this.url = checkoutData.result.url;
          this.btnDisable = false
  
          console.log(this.url);
          
          // Assuming 'url' is the URL you want to open in a new tab
          this.openInNewTab(this.url);

        } catch (checkoutError) {
          console.error("Error during checkout:", checkoutError);
        }
      } catch (addError) {
        console.error("Error while adding data:", addError);
      }
    }
  }

  openInNewTab(url: string): void {
    window.open(url, '_blank');
  }
  

  updatedata(){
    const senddata: any = {
      id: this.tid,
      status: 'OPEN'
    };
    const senddata2: any = {}
    this.loaderservice.changeLoaderStatus(true)
    this.homeService.updateData(this.tid,senddata2).subscribe(
      (data:any) => {
        console.log(data);
        this.alertService.success(data.message)
        // this.router.navigate(['/']);
        let url = 'https://www.cornwarriorstv.com/top-crop-soil-challenge/'
        window.location.href = url;
        this.loaderservice.changeLoaderStatus(false)
      },
      (err:any) => {
        this.btnDisable = false
        this.alertService.error("err.err.error")
      }
    )
  }
  
}
