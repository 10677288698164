import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpClient) { }

  addData(data:any): Observable<any>{
    return this.http.post(
      environment.apiUrl + 'tcp-form',
      data
    )
  }

  getData(transaction_id:any):Observable<any>{
    return this.http.get(
      environment.apiUrl + '/tcp-form?id=' + transaction_id
    )
  }

  checkout(id:any):Observable<any>{
    return this.http.post(
      environment.apiUrl + 'create-checkout', id
    )
  }

  updateData(id:any,data:any):Observable<any>{
    return this.http.patch(
      environment.apiUrl + 'tcp-form/' + id , data
    )
  }
}
